import React, { useState,useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { var_api } from '../constant.js';
import "./rawMaterial-master.css";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Tooltip } from 'reactstrap';
import Switch from "react-switch";
import { Table, Card} from 'react-bootstrap';
import Pagination from 'react-js-pagination';

function RawMaterial(){
    const history = useHistory();
    const shopName= localStorage.getItem('shopName');
    const [item_name,setitem_name] = useState("");
    const [stock_code,setstock_code] =  useState("");
    const [description, setDescription] = useState("");
    const [stock_category, setStockCategory] = useState("");
    const [stock_type, setStockType] = useState("");
    const [UOM, setUOM] = useState("");
    const [default_unit_purchase_price, setDefaultUnitPurchasePrice] = useState("");
    const [default_upp_currency, setDefaultUppCurrency] = useState("");
    const [default_upp_primary_vendor, setDefaultUppPrimaryVendor] = useState("");
    const [default_unit_selling_price, setDefaultUnitSellingPrice] = useState("");
    const [default_selling_currency, setDefaultSellingCurrency] = useState("");
    const [rawItems, setRawItems] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ searchQuery,setSearchQuery] = useState('');
    const [isEdit, setisedit] = useState(false);
    const [ postloading,setPostLoading ] = useState(false);
    const [ putloading,setPutLoading ] = useState(false);
    const [updatedMaterial, setUpdatdeMaterial] = useState({
      id:null,
      item_name:"",
      stock_code:""
    });
    const [show, setShow] = useState(false);
    const [puttooltipOpen, setPutToolTipOpen] = useState([]);
    const [deletetooltipOpen, setDeleteToolTipOpen] = useState([]);
    const [currencys, setCurrencys] = useState([]);
    const [uoms, setUoms] = useState([]);
    const [isOpenSubStockModal, setIsOpenSubStockModal] = useState(false);
    const [subLoading, setSubLoading] = useState(false);
    const [subRawItems, setSubRawItems] = useState([]);
    const [selectedStockCode, setSelectedStockCode] = useState(" ");
    const [isCreateSub, setIsCreateSub] = useState(false);
    const [newMaterial, setNewMaterial] = useState({
      shop_id: localStorage.getItem('shopId'), // Manually set value
      stock_code: "",
      vendor: "",
      UOM: "",
      unit_purchase_price: "",
      upp_currency: "",
      is_disabled: 0, // Manually set value
      valid_from: "",
      expired_at: "",
    });
    const [subPostLoading, setSubPostLoading] = useState(false);
    const [deleteSubDetails, setDeleteSubDetails] = useState(null);
    const [isOpenSubDelete, setIsOpenSubDelete] = useState(false);
    const [modalMode, setModalMode] = useState('create'); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const itemsPerPage = 25; 
    const [ subSearchQuery,setSubSearchQuery] = useState('');
    const [SubCurrentPage, setSubCurrentPage] = useState(1);
    const [SubTotalItems, setSubTotalItems] = useState(0);
    const [SubStartIndex, setSubStartIndex] = useState(0);
    const SubItemsPerPage = 25; 



    //redirect to home page
    const handleHome = () =>{
      history.push('/home')
    }

    const togglePutTooltip = (index) => {
      const putTooltipOpen = [...puttooltipOpen];
      putTooltipOpen[index] = !putTooltipOpen[index];
      setPutToolTipOpen(putTooltipOpen);
    };
    
    
    const toggleDeleteTooltip = (index) => {
      const deleteTooltipOpen = [...deletetooltipOpen];
      deleteTooltipOpen[index] = !deleteTooltipOpen[index];
      setDeleteToolTipOpen(deleteTooltipOpen);
    };

    //list the catgories in select
    useEffect(() => {
        fetchItems();
        fetchCURRENCYs();
        fetchUOMs();
    }, []);


    const fetchCURRENCYs = async () => {
    
      try {
        const response = await axios.get(`${var_api}/api/currency-master/get-currency`);
        const use = response.data;
        setCurrencys(use);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };
    


//fetch the stock items
    const fetchItems = async () => {
        setLoading(true);
        const startIndexCalc = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndexCalc + itemsPerPage;
        const shopId = localStorage.getItem('shopId');
       try {
        const response = await axios.get(`${var_api}/api/raw-items/get-items/${shopId} `);
        const use = response.data
      setRawItems(use.slice(startIndexCalc, endIndex));
      setTotalItems(use.length);
      setStartIndex(startIndexCalc); // Update startIndex state variable
      console.log("categories",rawItems);
      setLoading(false);
      }catch (error) {
        console.log('Error fetching data:', error);
        setLoading(false);
        setRawItems([]);
        setTotalItems(0);
        setStartIndex(0);
        }
      };

      //onchange for pagination
      const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };



      //fetch sub stock items
      const fetchSubStock = async (stcode) => {
        setSubLoading(true);
        const startIndexCalc = (SubCurrentPage - 1) * SubItemsPerPage;
        const endIndex = startIndexCalc + SubItemsPerPage;

        const shopId = localStorage.getItem('shopId');
       try {
        const response = await axios.get(`${var_api}/api/sub-raw-items/get-items/${shopId}/${stcode}`);
        const use = response.data
        setSubRawItems(use.slice(startIndexCalc, endIndex));
        setSubTotalItems(use.length);
        setSubStartIndex(startIndexCalc);
      console.log("categories",rawItems)
      setSubLoading(false);
      }catch (error) {
        console.log('Error fetching data:', error);
        setSubLoading(false);
        setSubRawItems([]);
        setSubTotalItems(0);
        setSubStartIndex(0);
        }
      };


        //onchange for pagination
        const handleSubPageChange = (pageNumber) => {
          setSubCurrentPage(pageNumber);
      };
  


       //search the sub stock 
       const filteredSubItems = subRawItems.filter((order) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          (order.stock_code && order.stock_code.toString().toLowerCase().includes(searchLower)) ||
          (order.vendor && order.vendor.toString().toLowerCase().includes(searchLower)) ||
          (order.unit_purchase_price && order.unit_purchase_price.toString().toLowerCase().includes(searchLower)) ||
          (order.valid_from && order.valid_from.toString().toLowerCase().includes(searchLower)) ||
          (order.expired_at && order.expired_at.toString().toLowerCase().includes(searchLower)) 
        );
      });



 
       // Function to convert string to camelCase
  const capitalizeEachWord = (str) => {
    return str.toLowerCase().replace(/(^|\s)\S/g, function (match) {
      return match.toUpperCase();
    });
  };

   //raw items post the form data
    const Submit = async (e) => {
      e.preventDefault();
      setPostLoading(true);

      try {
        const response = await axios.post(`${var_api}/api/raw-items/post`, {
            item_name: capitalizeEachWord(item_name), 
            stock_code: stock_code,
            shop_id: localStorage.getItem('shopId'),
            description: description,
            stock_category: stock_category,
            stock_type: stock_type,
            UOM: UOM,
            default_unit_purchase_price: default_unit_purchase_price,
            default_upp_currency: default_upp_currency,
            default_upp_primary_vendor: default_upp_primary_vendor,
            default_unit_selling_price: default_unit_selling_price,
            default_selling_currency: default_selling_currency,
            is_disabled: 0,
            is_obsolete: 0,
        });
        console.log("response",response)
    
        if (response.status === 200) {
          Swal.fire({
            icon:"success",
            text:"Raw Items Created Successfully",
            confirmButtonColor:"#007500"
          })
          setstock_code(" ");
          setitem_name(" ");
          setDescription("");
          setStockCategory("");
          setStockType("");
          setUOM("");
          setDefaultUnitPurchasePrice("");
          setDefaultUppCurrency("");
          setDefaultUppPrimaryVendor("");
          setDefaultUnitSellingPrice("");
          setDefaultSellingCurrency("");
          setPostLoading(false);
          fetchItems();
          console.log('Data inserted successfully.')
      } 
      } catch (error) {
        
        Swal.fire({
            icon:"error",
            text:`Item Name or Stock Code Already Exist`.toUpperCase(),
            confirmButtonColor:"#dc3545"
          })
          setPostLoading(false);
        console.error('Error:',error);
        // Handle error scenario as needed
      }
    };

    const handleToReset = () => {
        setstock_code(" ");
        setitem_name(" "); 
        setDescription("");
        setStockCategory("");
        setStockType("");
        setUOM("");
        setDefaultUnitPurchasePrice("");
        setDefaultUppCurrency("");
        setDefaultUppPrimaryVendor("");
        setDefaultUnitSellingPrice("");
        setDefaultSellingCurrency("");
    }



      //get the value in search and set the setstate
      const handleSearchInput = (e) => {
        setSearchQuery(e.target.value)
        };


         //search the transaction data
      const filteredItems = rawItems.filter((order) => {
        const searchLower = searchQuery.toLowerCase();
        return (
          (order.item_name && order.item_name.toString().toLowerCase().includes(searchLower)) ||
          (order.stock_code && order.stock_code.toString().toLowerCase().includes(searchLower)) ||
          (order.description && order.description.toString().toLowerCase().includes(searchLower)) ||
          (order.stock_category && order.stock_category.toString().toLowerCase().includes(searchLower)) ||
          (order.stock_type && order.stock_type.toString().toLowerCase().includes(searchLower)) ||
          (order.default_unit_purchase_price && order.default_unit_purchase_price.toString().toLowerCase().includes(searchLower)) ||
          (order.default_upp_primary_vendor && order.default_upp_primary_vendor.toString().toLowerCase().includes(searchLower)) ||
          (order.default_unit_selling_price && order.default_unit_selling_price.toString().toLowerCase().includes(searchLower))
          
        );
      });

      //to open the form for slot updation 
    const handleEditItem = async (data) => {
      setisedit(true);
      setUpdatdeMaterial({
        id: data.id,
        item_name: data.item_name,
        stock_code: data.stock_code,
        description: data.description,
        stock_category: data.stock_category,
        stock_type: data.stock_type,
        UOM: data.UOM,
        default_unit_purchase_price: data.default_unit_purchase_price,
        default_upp_currency: data.default_upp_currency,
        default_upp_primary_vendor: data.default_upp_primary_vendor,
        default_unit_selling_price: data.default_unit_selling_price,
        default_selling_currency: data.default_selling_currency,
        is_disabled: data.is_disabled,
        is_obsolete: data.is_obsolete
      });
        // const { value: formValues } = await Swal.fire({
        //     title: 'Update Currrency',
        //     html: `
        //      <label class="swalLa">Item Name</label>
        //      <br/>
        //       <input id="swal-input1" class="swal2-input fin" name="item_name" placeholder="Item Name" value="${data.item_name || ''}">
        //       <br/>
        //       <br/>
        //       <label class="swalLa">Stock Code</label>
        //       <input id="swal-input2" class="swal2-input fin" name="stock_code" placeholder="Stock Code" value="${data.stock_code || ''}">
             
        //     `,
        //     focusConfirm: false,
        //     showCancelButton: true,
        //     confirmButtonColor: '#007500',
        //     confirmButtonText: 'Submit',
        //     preConfirm: () => {
        //       return new Promise(async (resolve) => {
        //         const input1 = document.getElementById('swal-input1').value;
        //         const input2 = document.getElementById('swal-input2').value;
        
        //         if (!input1 || !input2) {
        //           Swal.showValidationMessage('Please fill in all the required fields');
        //           resolve(null);
        //         } else {

        //           const requestData = {
        //             item_name: input1|| data.item_name,
        //             stock_code: input2 || data.stock_code
        //           };
        
        //           try {
        //             // Make a PUT request to update the UOM
        //             const response = await axios.put(${var_api}/api/raw-items/put/${data.id}, requestData, {
        //               headers: {
        //                 'Content-Type': ' application/json', 
        //               },
        //             });
        
        //             if (response.status === 200) {
        //                 fetchItems();
        //               // Category updated successfully
        //               Swal.fire({
        //                 icon: 'success',
        //                 text: 'Raw Items updated successfully',
        //                 confirmButtonColor: '#007500',
        //               });
        //             } else {
        //               Swal.fire({
        //                 icon: 'error',
        //                 text: 'Failed to update Raw Items',
        //                 confirmButtonColor: '#007500',
        //               });
        //             }
        //           } catch (error) {
        //             console.error('Error updating Raw Items:', error);
        //             Swal.fire('Error', 'Failed to update Raw Items. Please try again.', 'error');
        //           }
        
        //           resolve(requestData);
        //         }
        //       });
        //     },
        //   });
      };


       //delete method for remove UOM
     const handleDeleteItem = (id,item) => {
      setUpdatdeMaterial({item_name:item,id:id});
      setShow(true);
       
      }


       //delete method for remove UOM
     const handleSubDeleteItem = (data) => {
      setDeleteSubDetails(data);
      setIsOpenSubDelete(true);
       
      }

       //handle to reset the update form
    const handleResetShop = () => {
      setUpdatdeMaterial({
        item_name:"",
        stock_code:""
      })
    }

   

    const handleUpdateMaterial = async(e) => {
      e.preventDefault();
      setPutLoading(true);
                 const requestData = {
                    item_name: capitalizeEachWord(updatedMaterial.item_name),
                    stock_code: updatedMaterial.stock_code,
                    description: updatedMaterial.description,
                    stock_category: updatedMaterial.stock_category,
                    stock_type: updatedMaterial.stock_type,
                    UOM: updatedMaterial.UOM,
                    default_unit_purchase_price: updatedMaterial.default_unit_purchase_price,
                    default_upp_currency: updatedMaterial.default_upp_currency,
                    default_upp_primary_vendor: updatedMaterial.default_upp_primary_vendor,
                    default_unit_selling_price: updatedMaterial.default_unit_selling_price,
                    default_selling_currency: updatedMaterial.default_selling_currency,
                    is_disabled: updatedMaterial.is_disabled,
                    is_obsolete: updatedMaterial.is_obsolete
                  };
        
                  try {
                    // Make a PUT request to update the UOM
                    const response = await axios.put(`${var_api}/api/raw-items/put/${updatedMaterial.id}`, requestData, {
                      headers: {
                        'Content-Type': ' application/json', 
                      },
                    });
        
                    if (response.status === 200) {
                        fetchItems();
                        setisedit(false);
                        setUpdatdeMaterial({
                          id:null,
                          item_name:"",
                          stock_code:"",
                          description: "",
                          stock_category: "",
                          stock_type: "",
                          UOM: "",
                          default_unit_purchase_price: "",
                          default_upp_currency: "",
                          default_upp_primary_vendor: "",
                          default_unit_selling_price: "",
                          default_selling_currency: "",
                          is_disabled: 0,
                          is_obsolete: 0
                        })
                      // Category updated successfully
                      Swal.fire({
                        icon: 'success',
                        text: 'Raw Items updated successfully',
                        confirmButtonColor: '#007500',
                      });
                    } else {
                      Swal.fire({
                        icon: 'error',
                        text: 'Failed to update Raw Items',
                        confirmButtonColor: '#007500',
                      });
                    }
                  } catch (error) {
                    console.error('Error updating Raw Items:', error);
                    Swal.fire('Error', 'Failed to update Raw Items. Please try again.', 'error');
                  }
                  finally{
                    setPutLoading(false);
                  }
    }


    //delete the raw item
    const handleDeleteMaterial = () => {
       axios
        .delete(`${var_api}/api/raw-items/item-delete/${updatedMaterial.id}`)
        .then((response) => {
          if (response.status === 200) {
            fetchItems();
            setShow(false);
            setUpdatdeMaterial({
              id:null,
              item_name:"",
              stock_code:""
            })
            Swal.fire("success","Raw Items Removed Successfully!","success")
            // Perform any other actions you need on success
          } else if (response.status === 404) {
            console.log('Raw Items not found');
            Swal.fire("error","Raw Items not found!","error")
          }
        })
        .catch((error) => {
          console.error('Error executing the request:', error);
          Swal.fire("error","Failed to Remove Raw Items!","error")
          // Handle the error as needed
        });
    }


//delete the sub stock item
    const handleDeleteSubStock = () => {
      axios
       .delete(`${var_api}/api/sub-raw-items/delete/${deleteSubDetails.id}`)
       .then((response) => {
         if (response.status === 200) {
           fetchSubStock(selectedStockCode);
           setIsOpenSubDelete(false);
           setDeleteSubDetails(null)
           Swal.fire("success","Sub Raw Items Removed Successfully!","success")
           // Perform any other actions you need on success
         } else if (response.status === 404) {
           console.log('Sub Raw Items not found');
           Swal.fire("error","Sub Raw Items not found!","error")
         }
       })
       .catch((error) => {
         console.error('Error executing the request:', error);
         Swal.fire("error","Failed to Remove Raw Items!","error")
         // Handle the error as needed
       });
   }




    const fetchUOMs = async () => {

      const shopId = localStorage.getItem('shopId');
     try {
      const response = await axios.get(`${var_api}/api/uom-master/get-uom/${shopId}`);
      const use = response.data
    setUoms(use);
    
    console.log("categories",uoms)
    }catch (error) {
      console.log('Error fetching data:', error);
      
      }
    };


    const handleOpenSubStockModal = (stcode) => {
      fetchSubStock(stcode);
      setSelectedStockCode(stcode);
      console.log("stock code",stcode);
      setIsOpenSubStockModal(true);
    }


    //open the sub stock create form modal  
    const handleOpenCreateSubStock = () => {
      setIsOpenSubStockModal(false);
      setIsCreateSub(true);
      setNewMaterial({
        ...newMaterial,
        vendor: "",
      UOM: "",
      unit_purchase_price: "",
      upp_currency: "",
      is_disabled: 0, // Manually set value
      valid_from: "",
      expired_at: "",
        stock_code: selectedStockCode
      })
      
      setModalMode("create")
    }

    const formatDateForAPI = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`; // Format date as DD-MM-YYYY
    };
  


     //sub raw items post the form data
     const subSubmit = async (e) => {
      e.preventDefault();
      setSubPostLoading(true);

      try {
        let response;
        if (modalMode === 'create') {
           response = await axios.post(`${var_api}/api/sub-raw-items/post`, {
            shop_id: parseInt(newMaterial.shop_id),
            stock_code: newMaterial.stock_code,
            vendor: newMaterial.vendor,
            UOM: parseInt(newMaterial.UOM),
            unit_purchase_price: parseFloat(newMaterial.unit_purchase_price),
            upp_currency: parseInt(newMaterial.upp_currency),
            is_disabled: newMaterial.is_disabled,
            valid_from: formatDateForAPI(newMaterial.valid_from),
            expired_at: formatDateForAPI(newMaterial.expired_at)
          });
          console.log("response",response)
        } else {
           response = await axios.put(`${var_api}/api/sub-raw-items/put/${newMaterial.id}`, {
            shop_id: parseInt(newMaterial.shop_id),
            stock_code: newMaterial.stock_code,
            vendor: newMaterial.vendor,
            UOM: parseInt(newMaterial.UOM),
            unit_purchase_price: parseFloat(newMaterial.unit_purchase_price),
            upp_currency: parseInt(newMaterial.upp_currency),
            is_disabled: newMaterial.is_disabled,
            valid_from: formatDateForAPI(newMaterial.valid_from),
            expired_at: formatDateForAPI(newMaterial.expired_at)
          });
          console.log("response",response)
        }
    
       
    
        if (response.status === 200) {
          setNewMaterial({
            shop_id: localStorage.getItem('shopId'), // Manually set value
      stock_code: "",
      vendor: "",
      UOM: "",
      unit_purchase_price: "",
      upp_currency: "",
      is_disabled: 0, // Manually set value
      valid_from: "",
      expired_at: ""
          })
          setSubPostLoading(false);
          setIsCreateSub(false);
          Swal.fire({
            icon:"success",
            text:`Sub Stock ${modalMode === "edit" ? "update" : "created"} Successfully`,
            confirmButtonColor:"#007500"
          })
          fetchSubStock(selectedStockCode);
          console.log('sub stock inserted successfully.')
          setIsOpenSubStockModal(true);
      } 
      } catch (error) {
        
        // Swal.fire({
        //     icon:"error",
        //     text:`Item Name or Stock Code Already Exist`.toUpperCase(),
        //     confirmButtonColor:"#dc3545"
        //   })
          setSubPostLoading(false);
        console.error('Error:',error);
        // Handle error scenario as needed
      }
    };


    const formatDate = (dateString) => {
      const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`; // Format date as YYYY-MM-DD
  };


    //open the edit sub stock modal
    const handleEditSubItem = (data) => {
      setModalMode('edit');
      setNewMaterial({
        ...data,
        valid_from: formatDate(data.valid_from),
        expired_at: formatDate(data.expired_at)
      });
      setIsCreateSub(true);
    };
    




    return(
        <>
        <div className='whole' style={{backgroundColor:"white"}}>
            <div class="splitra leftra">
                <div class="centered">
                  <br/>
                    <h3 className='usheraw'>Create Raw material</h3>
                    <br/>
                    <div style={{marginLeft:"12px",padding:"12px", height:"600px", overflowX: "hidden"}}>

                    <form onSubmit={Submit}>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"15px"}}>Item Name</lable>
            <br/>
            <input  className="form-input" type='text' name='item_name' placeholder='enter item name'
             required
             value={item_name}
             onChange={(e) => setitem_name(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
            <lable style={{fontWeight:"bold",fontSize:"14px",marginLeft:"15px"}}>Stock Code</lable>
            <input  className="form-input" type='text' name='stock_code' placeholder='enter stock code'
            required
             value={stock_code}
             onChange={(e) => setstock_code(e.target.value)}/>
            </div>
            <br/>
            <div className='mb-5'>
    <label style={{fontWeight:"bold",fontSize:"14px",marginLeft:"15px"}}>Description</label>
    <input className="form-input" type='text' name='description' placeholder='Enter description' value={description} onChange={(e) => setDescription(e.target.value)} />
  </div>
  <br/>
  <div className='mb-5'>
    <label style={{fontWeight:"bold",fontSize:"14px",marginLeft:"15px"}}>Stock Category</label>
    <input className="form-input" type='text' name='stock_category' placeholder='Enter stock category' value={stock_category} onChange={(e) => setStockCategory(e.target.value)} required/>
  </div>
  <br/>
  <div className='mb-5'>
    <label style={{fontWeight:"bold",fontSize:"14px",marginLeft:"15px"}}>Stock Type</label>
    <input className="form-input" type='text' name='stock_type' placeholder='Enter stock type' value={stock_type} onChange={(e) => setStockType(e.target.value)}  required/>
  </div>
  <br/>
  <div className='mb-5'>
    <label style={{fontWeight:"bold",fontSize:"14px",marginLeft:"23px"}}>UOM</label>
    <select className="form-input" name='default_selling_currency' value={UOM} onChange={(e) => setUOM(e.target.value)} required>
      <option value="">Select Currency</option>
      {uoms.map((uomname) => (
        <option key={uomname.id} value={uomname.id}>
          {uomname.uom_name}
        </option>
      ))}
    </select>
  </div>
  <br/>
  <div className='mb-5'>
    <label style={{fontWeight:"bold",fontSize:"14px",marginLeft:"15px"}}>Default Unit Purchase Price</label>
    <input className="form-input" type='number' name='default_unit_purchase_price' placeholder='Enter purchase price' value={default_unit_purchase_price} onChange={(e) => setDefaultUnitPurchasePrice(e.target.value)} required/>
  </div>
  <br/>
  <div className='mb-5'>
    <label style={{fontWeight:"bold",fontSize:"14px",marginLeft:"15px"}}>Default UPP Currency</label>
    <select className="form-input" name='default_upp_currency' value={default_upp_currency} onChange={(e) => setDefaultUppCurrency(e.target.value)} required>
      <option value="">Select Currency</option>
      {currencys.map((currency) => (
        <option key={currency.id} value={currency.id}>
          {currency.currency_name}
        </option>
      ))}
    </select>
  </div>
  <br/>
  <div className='mb-5'>
    <label style={{fontWeight:"bold",fontSize:"14px",marginLeft:"15px"}}>Default UPP Primary Vendor</label>
    <input className="form-input" type='text' name='default_upp_primary_vendor' placeholder='Enter primary vendor' value={default_upp_primary_vendor} onChange={(e) => setDefaultUppPrimaryVendor(e.target.value)} required/>
  </div>
  <br/>
  <div className='mb-5'>
    <label style={{fontWeight:"bold",fontSize:"14px",marginLeft:"15px"}}>Default Unit Selling Price</label>
    <input className="form-input" type='number' name='default_unit_selling_price' placeholder='Enter selling price' value={default_unit_selling_price} onChange={(e) => setDefaultUnitSellingPrice(e.target.value)} required/>
  </div>
  <br/>
  <div className='mb-5'>
    <label style={{fontWeight:"bold",fontSize:"14px",marginLeft:"15px"}}>Default Selling Currency</label>
    <select className="form-input" name='default_selling_currency' value={default_selling_currency} onChange={(e) => setDefaultSellingCurrency(e.target.value)} required>
      <option value="">Select Currency</option>
      {currencys.map((currency) => (
        <option key={currency.id} value={currency.id}>
          {currency.currency_name}
        </option>
      ))}
    </select>
  </div>
           
            <br/>
            <br/>
           {postloading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <button className='btn btn-success' style={{float:"right",marginLeft:"5px"}} disabled={postloading}>Submit</button>
        )} {' '}
            </form>
            
            {' '}<button className='btn btn-secondary' style={{float:"right"}}  onClick={handleToReset} >Reset</button> {' '}
                       
                        
                    </div>
                   
                </div>
            </div>

            <div class="split right">
            <nav className="navbar" 
            style={{boxShadow: "0px 0px 1px 0px black",backgroundColor:"green",border:"none",padding:"5px",height:"50px",width:"1065px",position:"relative",top:"-10px"}} >
         
                <h4 style={{ fontFamily: "cursive", fontWeight: "bolder",marginLeft: "30px", color: "white" }}>{ shopName }</h4>
                <button className="btn" onClick={handleHome} style={{float:"right",backgroundColor:"white",color:"green"}}
         ><i class="bi bi-house"></i> Go to Home</button>
             
      </nav>
      <div style={{border:"none",padding:"20px",width:"1020px",height:"640px",margin:"auto",borderRadius:"15px",backgroundColor:"white",boxShadow:" rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px"}}>
      
      <div>
        
      <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
      style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} onChange={handleSearchInput}/>
      {/* <h3 style={{textAlign:"center",fontFamily:"cursive",fontWeight:"bolder",textDecoration:"underline"}}>Raw Materials List</h3> */}
      <br/>
      <br/>
      <br/>
     <div className="table-container" style={{ height:"500px", overflowX: 'auto', overflowY: 'hidden' }}>
  <table class="table table-hover small-first-col" style={{border:"none",borderColor:"grey",width:"90%",margin:"auto"}}>
  <thead>
    <tr style={{fontWeight:"bolder"}}>
    <th scope="col">S.No</th>
      <th scope="col">Item Name</th>
      <th scope="col">Stock Code</th>
      <th scope="col">Description</th>
      <th scope="col">Stock Category</th>
      <th scope="col">Stock Type</th>
      <th scope="col">Purchase Price</th>
      <th scope="col">Primary Vendor</th>
      <th scope="col">Selling Price</th>
      <th scope="col">Action</th>
      <th scope="col">Open</th>
    </tr>
  </thead>
  <tbody class="body-half-screen">
   {filteredItems.length > 0 ? (
   filteredItems.map((data,index) =>(

    <tr>
       <th scope="row">{index + 1}</th>
      <td style={{ fontSize:"15px"}}>{data.item_name}</td>
      <td style={{fontSize:"15px"}}>{data.stock_code}</td>
      <td style={{fontSize:"15px"}}>{data.description}</td>
      <td style={{fontSize:"15px"}}>{data.stock_category}</td>
      <td style={{fontSize:"15px"}}>{data.stock_type}</td>
      <td style={{fontSize:"15px"}}>{data.default_unit_purchase_price}</td>
      <td style={{fontSize:"15px"}}>{data.default_upp_primary_vendor}</td>
      <td style={{fontSize:"15px"}}>{data.default_unit_selling_price}</td>
      <td>
      <Tooltip
        isOpen={puttooltipOpen[index]} // Use tooltip state based on index
        target={`putButton${index}`} // Specify the target ID of the button
        toggle={() => togglePutTooltip(index)}
        placement='left'
      >
          Edit Material
        </Tooltip>
      <i class="bi bi-pencil-square" id={`putButton${index}`} onClick={() => {handleEditItem(data); togglePutTooltip(index);}}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
      <Tooltip
        isOpen={deletetooltipOpen[index]} // Use tooltip state based on index
        target={`deleteButton${index}`} // Specify the target ID of the button
        toggle={() => toggleDeleteTooltip(index)}
        placement='right'
      >
          Delete Material
        </Tooltip>
      <i class="bi bi-trash" id={`deleteButton${index}`} onClick={() => {handleDeleteItem(data.id,data.item_name); toggleDeleteTooltip(index); }}  style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i>
      </td>
      <th scope="col" onClick={()=>handleOpenSubStockModal(data.stock_code)}><i class="bi bi-arrow-right-circle-fill"  style={{fontSize:"20px",color:"blue", fontWeight:"bolder"}}></i></th>
    </tr>
   ))
   ) : (
    <tr>
      <td colSpan="12" style={{ textAlign: "center" }}>
        No Item Found
      </td>
    </tr>
   )}
 

   </tbody>

</table>


      </div>
      <Pagination
    activePage={currentPage}
    itemsCountPerPage={itemsPerPage}
    totalItemsCount={totalItems} // Update to reflect total items for pagination
    pageRangeDisplayed={5}
    onChange={handlePageChange}
    itemClass="page-item"
    linkClass="page-link"
    innerClass="pagination justify-content-end"
    activeClass="active-pagination" // Custom class for the active pagination item
/>

<br/>
<br/>


        </div>
      
      </div>
            </div>


            
<Modal
        show={isEdit}
        onHide={()=>setisedit(false)}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title> Update Raw Material </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form  className="py-2" onSubmit={handleUpdateMaterial}>
        <div className="row">
        <div className="col">
          <label for="input1" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Item Name</label>
          <input type="text" className="form-control" placeholder="enter item name" id="input1" name="item_name" value={updatedMaterial.item_name} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, item_name: e.target.value})} style={{borderRadius:"12px"}} required />
        </div>
        <div className="col">
          <label for="input2" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Stock Code</label>
          <input type="text" className="form-control" placeholder="enter stock code" id="input2" name="stock_code" value={updatedMaterial.stock_code} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, stock_code: e.target.value})} style={{borderRadius:"12px"}} required />
        </div>
      </div>
      <br/>
      <div className='row'>
        <div className='col'>
          <label style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Description</label>
          <input className="form-control" type='text' name='description' placeholder='Enter description' value={updatedMaterial.description} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, description: e.target.value})} style={{borderRadius:"12px"}} />
        </div>
        <div className='col'>
        <label style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Stock Category</label>
        <input className="form-control" type='text' name='stock_category' placeholder='Enter stock category' value={updatedMaterial.stock_category} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, stock_category: e.target.value})} style={{borderRadius:"12px"}} required/>
        </div>
      </div>
      <br/>
      <div className='row'>
        <div className='col'>
          <label style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Stock Type</label>
          <input className="form-control" type='text' name='stock_type' placeholder='Enter stock type' value={updatedMaterial.stock_type} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, stock_type: e.target.value})} style={{borderRadius:"12px"}} required/>
        </div>
        <div className='col'>
          <label style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>UOM</label>
          <select className="form-control" name='UOM' value={updatedMaterial.UOM} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, UOM: e.target.value})} style={{borderRadius:"12px"}} required>
            <option value="">Select UOM</option>
          {uoms.map((uomname) => (
            <option key={uomname.id} value={uomname.id}>
              {uomname.uom_name}
            </option>
          ))}
          </select>
        </div>
      </div>
      <br/>
      <div className='row'>
        <div className='col'>
          <label style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Default Unit Purchase Price</label>
          <input className="form-control" type='number' name='default_unit_purchase_price' placeholder='Enter purchase price' value={updatedMaterial.default_unit_purchase_price} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, default_unit_purchase_price: e.target.value})} style={{borderRadius:"12px"}} required/>
        </div>
        <div className='col'>
          <label style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Default UPP Currency</label>
          <select className="form-control" name='default_upp_currency' value={updatedMaterial.default_upp_currency} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, default_upp_currency: e.target.value})} style={{borderRadius:"12px"}} required>
            <option value="">Select Currency</option>
            {currencys.map((currency) => (
            <option key={currency.id} value={currency.id}>
              {currency.currency_name}
            </option>
            ))}
          </select>
        </div>
      </div>
      <br/>
      <div className='row'>
        <div className='col'>
          <label style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Default UPP Primary Vendor</label>
          <input className="form-control" type='text' name='default_upp_primary_vendor' placeholder='Enter primary vendor' value={updatedMaterial.default_upp_primary_vendor} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, default_upp_primary_vendor: e.target.value})} style={{borderRadius:"12px"}} required/>
        </div>
        <div className='col'>
          <label style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Default Unit Selling Price</label>
          <input className="form-control" type='number' name='default_unit_selling_price' placeholder='Enter selling price' value={updatedMaterial.default_unit_selling_price} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, default_unit_selling_price: e.target.value})} style={{borderRadius:"12px"}} required/>
        </div>
      </div>
      <br/>
      <div className='row'>
        <div className='col'>
          <label style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Default Selling Currency</label>
          <select className="form-control" name='default_selling_currency' value={updatedMaterial.default_selling_currency} onChange={(e) => setUpdatdeMaterial({...updatedMaterial, default_selling_currency: e.target.value})} style={{borderRadius:"12px"}} required>
            <option value="">Select Currency</option>
             {currencys.map((currency) => (
            <option key={currency.id} value={currency.id}>
              {currency.currency_name}
            </option>
            ))}
          </select>
        </div>
        <div className='col'>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                  <Switch
                    checked={updatedMaterial.is_disabled}
                    onChange={(checked) => setUpdatdeMaterial({...updatedMaterial, is_disabled: checked})}
                  />
                  <label style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "15px" }}>Is Disabled</label>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Switch
                    checked={updatedMaterial.is_obsolete}
                    onChange={(checked) => setUpdatdeMaterial({...updatedMaterial, is_obsolete: checked})}
                  />
                  <label style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "15px" }}>Is Obsolete</label>
                </div>
              </div>
        </div>
      </div>
      <br/>
   
         {
          putloading ? (
            <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          ) :  <button className="btn btn-success" style={{float:"right",margin:"5px",borderRadius:"15px"}}>Update</button>
         }
         
          </form>
          <button className="btn btn-danger" onClick={()=>setisedit(false)} style={{borderRadius:"15px",float:"right",marginTop:"-1px"}}>Cancel</button>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>


            <Modal
        show={show}
        onHide={()=>setShow(false)}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px",backgroundColor:"white"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM!</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete Item{" "}
          <span style={{color:"red",textTransform:"uppercase",fontSize:"22px"}}>{updatedMaterial.item_name}</span> ?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={()=>setShow(false)}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDeleteMaterial}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal> 




      <Modal
        show={isOpenSubStockModal}
        onHide={()=>setIsOpenSubStockModal(false)}
        backdrop="static"
        keyboard={false}
        size="xl" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton > 
          <Modal.Title>Stock Details <span style={{fontSize:"20px", color:"red", fontWeight:"bolder"}}>({selectedStockCode})</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Card style={{maxWidth:"95%", height:"auto", borderRadius:"20px", margin:"auto", padding:"20px"}} > 
          
            <Card.Body className="p-2">
         
            <div>
        
        <input class="form-control" type="Search" placeholder="Search" id="example-search-input" 
        style={{width:"230px",borderRadius:"25px",border:"1px solid black",float:"right"}} onChange={handleSearchInput}/>
        <button className='btn btn-primary' style={{float:"right", marginRight:"10px"}} onClick={handleOpenCreateSubStock}>Create Stock Detail</button>
        {/* <h3 style={{textAlign:"center",fontFamily:"cursive",fontWeight:"bolder",textDecoration:"underline"}}>Raw Materials List</h3> */}
        <br/>
        <br/>
        <br/>
       <div className="table-container" style={{ height:"500px", overflowX: 'auto', overflowY: 'hidden' }}>
    <table class="table table-hover small-first-col" style={{border:"none",borderColor:"grey",width:"90%",margin:"auto"}}>
    <thead>
      <tr style={{fontWeight:"bolder"}}>
      <th scope="col">S.No</th>
        <th scope="col">Stock Code</th>
        <th scope="col">Vendor</th>
        <th scope="col">UOM</th>
        <th scope="col">Unit Purchase Price</th>
        <th scope="col">UPP Currency</th>
        <th scope="col">Valid From</th>
        <th scope="col">Expiry Date</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody class="body-half-screen">
     {filteredSubItems.length > 0 ? (
     filteredSubItems.map((data,index) =>(
  
      <tr>
         <th scope="row">{index + 1}</th>
        <td style={{ fontSize:"15px"}}>{data.stock_code}</td>
        <td style={{fontSize:"15px"}}>{data.vendor}</td>
        <td style={{fontSize:"15px"}}>{data.uom_name}</td>
        <td style={{fontSize:"15px"}}>{data.unit_purchase_price}</td>
        <td style={{fontSize:"15px"}}>{data.currency_name}</td>
        <td style={{fontSize:"15px"}}>{data.valid_from}</td>
        <td style={{fontSize:"15px"}}>{data.expired_at}</td>
        <td>
        <Tooltip
          isOpen={puttooltipOpen[index]} // Use tooltip state based on index
          target={`putButton${index}`} // Specify the target ID of the button
          toggle={() => togglePutTooltip(index)}
          placement='left'
        >
            Edit Material
          </Tooltip>
        <i class="bi bi-pencil-square" id={`putButton${index}`} onClick={() => {handleEditSubItem(data); togglePutTooltip(index);}}  style={{fontSize:"20px",color:"rgb(9, 97, 9)"}}></i>{' '}
        <Tooltip
          isOpen={deletetooltipOpen[index]} // Use tooltip state based on index
          target={`deleteButton${index}`} // Specify the target ID of the button
          toggle={() => toggleDeleteTooltip(index)}
          placement='right'
        >
            Delete Material
          </Tooltip>
        <i class="bi bi-trash" id={`deleteButton${index}`} onClick={() => {handleSubDeleteItem(data); toggleDeleteTooltip(index); }}  style={{fontSize:"21px",color:"rgb(175, 2, 2)"}}></i>
        </td>
      </tr>
     ))
     ) : (
      <tr>
        <td colSpan="11" style={{ textAlign: "center" }}>
          No Item Found
        </td>
      </tr>
     )}
   
  
     </tbody>
  
  </table>
  
        </div>
        <Pagination
    activePage={SubCurrentPage}
    itemsCountPerPage={SubItemsPerPage}
    totalItemsCount={SubTotalItems} // Update to reflect total items for pagination
    pageRangeDisplayed={5}
    onChange={handleSubPageChange}
    itemClass="page-item"
    linkClass="page-link"
    innerClass="pagination justify-content-end"
    activeClass="active-pagination" // Custom class for the active pagination item
/>

  <br/>
  <br/>
  
  
          </div>
            

               
             
              </Card.Body>
          
            </Card>

        </Modal.Body>
      </Modal> 



      <Modal
      show={isCreateSub}
      onHide={() => setIsCreateSub(false)}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title> {modalMode === 'create' ? 'Create' : 'Edit'} Sub Raw Material </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="py-2" onSubmit={subSubmit}>
          <div className="row">
            <div className="col">
              <label htmlFor="stock_code" style={{color:"green", fontFamily:"serif", fontWeight:"bolder"}}>Stock Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter stock code"
                id="stock_code"
                name="stock_code"
                value={newMaterial.stock_code}
                style={{borderRadius:"12px"}}
                required
                readOnly
              />
            </div>
            <div className="col">
              <label htmlFor="vendor" style={{color:"green", fontFamily:"serif", fontWeight:"bolder"}}>Vendor</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter vendor"
                id="vendor"
                name="vendor"
                value={newMaterial.vendor}
                onChange={(e) => setNewMaterial({...newMaterial, vendor: e.target.value})}
                style={{borderRadius:"12px"}}
                required
              />
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col">
              <label htmlFor="UOM" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>UOM</label>
              <select
                className="form-control"
                name="UOM"
                value={newMaterial.UOM}
                onChange={(e) => setNewMaterial({...newMaterial, UOM: e.target.value})}
                style={{borderRadius:"12px"}}
                required
              >
                <option value="">Select UOM</option>
                {uoms.map((uomname) => (
                  <option key={uomname.id} value={uomname.id}>
                    {uomname.uom_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <label htmlFor="unit_purchase_price" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Unit Purchase Price</label>
              <input
                type="number"
                className="form-control"
                name="unit_purchase_price"
                placeholder="Enter purchase price"
                value={newMaterial.unit_purchase_price}
                onChange={(e) => setNewMaterial({...newMaterial, unit_purchase_price: e.target.value})}
                style={{borderRadius:"12px"}}
                required
              />
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col">
              <label htmlFor="upp_currency" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Currency</label>
              <select
                className="form-control"
                name="upp_currency"
                value={newMaterial.upp_currency}
                onChange={(e) => setNewMaterial({...newMaterial, upp_currency: e.target.value})}
                style={{borderRadius:"12px"}}
                required
              >
                <option value="">Select Currency</option>
                {currencys.map((currency) => (
                  <option key={currency.id} value={currency.id}>
                    {currency.currency_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
            <label htmlFor="valid_from" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Valid From</label>
              <input
                type="date"
                className="form-control"
                name="valid_from"
                value={newMaterial.valid_from}
                onChange={(e) => setNewMaterial({...newMaterial, valid_from: e.target.value, expired_at:""})}
                style={{borderRadius:"12px"}}
                required
              />
              {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                  <Switch
                    checked={newMaterial.is_disabled}
                    onChange={(checked) => setNewMaterial({...newMaterial, is_disabled: checked})}
                  />
                  <label style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "15px" }}>Is Disabled</label>
                </div>
              </div> */}
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col">
              <label htmlFor="expired_at" style={{color:"green",fontFamily:"serif",fontWeight:"bolder"}}>Expired At</label>
              <input
                type="date"
                className="form-control"
                name="expired_at"
                value={newMaterial.expired_at}
                onChange={(e) => setNewMaterial({...newMaterial, expired_at: e.target.value})}
                style={{borderRadius:"12px"}}
                required
                min={newMaterial.valid_from}
              />
            </div>
            <div className="col">
              {
                modalMode == "edit" ? (
                  <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                  <Switch
                    checked={newMaterial.is_disabled}
                    onChange={(checked) => setNewMaterial({...newMaterial, is_disabled: checked})}
                  />
                  <label style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "15px" }}>Is Disabled</label>
                </div>
                ) : null
              }
              </div>
          </div>
          <br/>
          {
            subPostLoading ? (
              <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />) :  <button className="btn btn-success" style={{float:"right", margin:"5px", borderRadius:"15px"}}>{modalMode === 'create' ? 'Create' : 'Update'}</button>
          }
        </form>
        <button className="btn btn-danger" onClick={() => setIsCreateSub(false)} style={{borderRadius:"15px", float:"right", marginTop:"-1px"}}>Cancel</button>
      </Modal.Body>
    </Modal>


    <Modal
        show={isOpenSubDelete}
        onHide={()=>setIsOpenSubDelete(false)}
        backdrop="static"
        keyboard={false}
        size="md" // Set size to large
        style={{ borderRadius: '20px' }} // Add border radius style
      >
        <Modal.Header closeButton style={{backgroundColor:"rgba(6, 105, 16, 0.367)",padding:"22px"}}>
          {/* <Modal.Title>confirmation</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>

         <div style={{padding:"20px",backgroundColor:"white"}}>
         <h4 style={{fontFamily:"monospace",fontWeight:"bolder",textAlign:"center"}}>CONFIRM!</h4>
          <h6 className='text-dark' style={{textAlign:"center",fontWeight:"bolder"}}>Are You Sure want to Delete Stock Code{" "}
          <span style={{color:"red",textTransform:"uppercase",fontSize:"22px"}}>{deleteSubDetails && deleteSubDetails.stock_code}</span> and  vendor {" "}
          <span style={{color:"red",textTransform:"uppercase",fontSize:"22px"}}>{deleteSubDetails && deleteSubDetails.vendor}</span>?</h6> 
         </div>
         {' '} 
          <Button variant="danger" style={{float:"right"}} onClick={()=>setIsOpenSubDelete(false)}>
            Close
          </Button>{' '} 
          <Button variant="success" style={{float:"right",marginRight:"5px"}} onClick={handleDeleteSubStock}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal> 
        </div>

       
        </>

    )
}




export default RawMaterial;